
import { computed, reactive } from "vue";
import { factoryService, userService } from "@/service";
import { useStore } from "@/store";
export default {
  setup() {
    const store = useStore();
    const userInfo = computed(() => store.state.userInfo);

    const storeInfo = reactive<any>({
      data: {},
      async getDetail() {
        const { data } = await factoryService.detail(store.state.userInfo?.stores_id);
        storeInfo.data = data.data[0];
      },
      userList: [],
      async getUserList() {
        const { data } = await userService.userLists({
          user_type: "3",
          stores_id: store.state.userInfo?.stores_id,
          job_state: "1"
        });
        const index = data.data.findIndex((user: any) => storeInfo.data.user_id === user.id);
        if (index) {
          data.data.unshift(...data.data.splice(index, 1));
        }
        storeInfo.userList = data.data;
      }
    });

    storeInfo.getDetail();
    storeInfo.getUserList();

    const afterRead = (file: any) => {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    };

    return {
      storeInfo,
      afterRead,
      userInfo
    };
  }
};
